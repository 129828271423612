import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogsPage from './pages/BlogsPage';
import NewBlogForm from './pages/NewBlogForm';
import NotFound from './pages/NotFound';
import Home from './pages/home'
import Blogs from './pages/blogs'
import Login from './pages/login';
import BlogDetail from './pages/BlogDetail'

const App = () => {

  const [isAuth, setIsAuth] = useState(false)


  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/new-blog" element={<NewBlogForm isAuth={isAuth} />} />
        <Route path="/login" element={<Login setIsAuth={setIsAuth} />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;