import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';

const NewBlogForm = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchBlog = async () => {
        const blogDoc = await getDoc(doc(db, 'blogs', id));
        if (blogDoc.exists()) {
          const blogData = blogDoc.data();
          setTitle(blogData.title);
          setContent(blogData.content);
        } else {
          navigate('/not-found');
        }
      };
      fetchBlog();
    }
  }, [id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const blogData = { title, content, author: {name: auth.currentUser.displayName, id: auth.currentUser.uid } };
    
    if (id) {
      await setDoc(doc(db, 'blogs', id), blogData);
    } else {
      await addDoc(collection(db, 'blogs'), blogData);
    }
    
    navigate('/blogs');
  };

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="min-h-screen bg-[#164e63] py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-3xl sm:mx-auto w-full px-2 sm:px-0">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-700 to-blue-700 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-3 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-[#1e2a3a] shadow-lg rounded-3xl sm:p-20">
          <div className="max-w-3xl mx-auto">
            <div>
              <h1 className="text-3xl font-bold text-gray-200 mb-8">{id ? 'Edit Blog' : 'Create New Blog'}</h1>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="title" className="block text-lg font-medium text-gray-300 mb-2">Blog Title</label>
                <input
                  id="title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="w-full px-3 py-2 text-gray-200 bg-[#2a3a4a] rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  placeholder="Enter your blog title"
                />
              </div>
              <div>
                <label htmlFor="content" className="block text-lg font-medium text-gray-300 mb-2">Blog Content</label>
                <textarea
                  id="content"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  required
                  rows="10"
                  className="w-full px-3 py-2 text-gray-200 bg-[#2a3a4a] rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  placeholder="Write your blog content here"
                />
              </div>
              <div>
                <button 
                  type="submit" 
                  className="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-bold py-3 px-4 rounded-md hover:from-cyan-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-opacity-50 transition-colors duration-300"
                >
                  {id ? 'Update Blog' : 'Create Blog'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBlogForm;