// components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-2xl font-bold mb-4">HyperAI</h3>
            <p className="text-gray-400">Empowering the future with intelligent solutions</p>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              {['Home', 'Services', 'About', 'Contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item.toLowerCase()}`} className="text-gray-400 hover:text-white transition duration-300">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            {/* <p className="text-gray-400">Islamabad, Pakistan</p> */}
            <p className="text-gray-400">information@hyperai.tech</p>
            {/* <p className="text-gray-400">(123) 456-7890</p> */}
          </div>
          <div className="w-full md:w-1/4">
            <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
            <div className="flex space-x-4">
            {[
              {social:'Twitter', link:'https://x.com/HyperAISol'}, 
              {social:'LinkedIn', link:'https://www.linkedin.com/company/hyperai-soultions/'},
              {social:'Instagram', link:'https://www.instagram.com/hyper_ai_solutions/'},
              {social:'YouTube', link:'https://www.youtube.com/@HyperAISolutions'}
            ].map((social, index) => (
              <a key={index} href={social.link} className="text-gray-400 hover:text-white transition duration-300">
                {social.social}
              </a>
            ))}

            </div>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-8 pt-8 text-center text-gray-400">
          <p>&copy; 2024 HyperAI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;