// App.js
import React, {useEffect} from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Services from '../components/Services';
import About from '../components/About';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import ProblemSolutionSection from '../components/ProblemSolutionSection';

function App() {


//   useEffect(() => {
//     const width = window.screen.width;
//     console.log(width);
//     const main = document.getElementById('home')
//     main.style.width =  `${width}px`;
//     main.style.overflowX= 'hidden'
//     // document.body.style.width = `${width}px`;  // Make sure to add 'px' when setting width
// }, []);

  





  return (
    <div className="min-h-screen bg-cyan-900 overflow-x-hidden">
      <Header />
      <main id='home' className='w-full flex flex-col justify-center overflow-x-hidden'>
        <Hero />
        <Services />
        <ProblemSolutionSection />
        <About />
        <div id='contact' className='pt-[150px] flex justify-center items-center'>
           <ContactForm />
        </div>
       
      </main>
      <Footer />
    </div>
  );
}

export default App;
