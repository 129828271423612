import React from 'react';
import { Github, Linkedin, Twitter, Server, Smartphone, Bot, Palette, Globe, Cloud, Facebook, Youtube, Instagram } from 'lucide-react';
import { motion } from 'framer-motion';

const FeatureItem = ({ icon: Icon, text }) => (
  <motion.div 
    className="flex items-center space-x-4 bg-[#327d99] bg-opacity-30 rounded-lg p-4 backdrop-blur-sm"
    whileHover={{ scale: 1.05, backgroundColor: 'rgba(59, 130, 246, 0.5)' }}
    transition={{ type: 'spring', stiffness: 300, damping: 10 }}
  >
    <Icon className="text-cyan-400" size={24} />
    <p className="text-gray-200">{text}</p>
  </motion.div>
);

const About = () => {
  return (
    <section id="about" className="py-20  bg-[#164e63] px-[10%] overflow-hidden relative">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCI+PGNpcmNsZSBjeD0iMzAiIGN5PSIzMCIgcj0iMC41IiBmaWxsPSIjMDBmZmZmIiBmaWxsLW9wYWNpdHk9IjAuMiIvPjwvc3ZnPg==')] opacity-20"></div>
      
      <motion.div 
        className="container mx-auto px-6 relative z-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="flex mt-[50px] flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 mb-12 lg:mb-0">
            <motion.h2 
              className="text-6xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-slate-300 to-[#357b94]"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              About Hyper AI Solutions
            </motion.h2>
            <motion.p 
              className="text-xl text-gray-300 mb-8 leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              Hyper AI Solutions specializes in creating responsive websites and powerful mobile apps that elevate your online presence. We harness AI to enhance business processes, offer custom chatbots for better customer engagement, and design intuitive interfaces that align with your brand. Our cloud solutions ensure scalable and secure infrastructure, helping your business grow efficiently.
            </motion.p>
            <motion.div 
              className="grid grid-cols-1 md:grid-cols-2 gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <FeatureItem icon={Globe} text="Responsive Website Development" />
              <FeatureItem icon={Smartphone} text="Powerful Mobile App Creation" />
              <FeatureItem icon={Server} text="AI-Enhanced Business Processes" />
              <FeatureItem icon={Bot} text="Custom Chatbot Solutions" />
              <FeatureItem icon={Palette} text="Intuitive UI/UX Design" />
              <FeatureItem icon={Cloud} text="Scalable Cloud Infrastructure" />
            </motion.div>
          </div>
          
          <motion.div 
            className="lg:w-1/3 flex flex-col items-center bg-[#327d99] bg-opacity-20 rounded-2xl p-8 backdrop-blur-lg"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            <h3 className="text-3xl font-semibold mb-6 text-cyan-400">Connect With Us</h3>
            <div className="flex space-x-6 mb-8">
              <motion.a 
                href="https://www.facebook.com/profile.php?id=61564009477117&mibextid=LQQJ4d" 
                className="text-gray-300 hover:text-cyan-400 transition-colors"
                whileHover={{ scale: 1.2, rotate: 5 }}
              >
                <Facebook size={36} />
              </motion.a>
              <motion.a 
                href="https://www.instagram.com/hyper_ai_solutions/" 
                className="text-gray-300 hover:text-cyan-400 transition-colors"
                whileHover={{ scale: 1.2, rotate: 5 }}
              >
                <Instagram size={36} />
              </motion.a>
              <motion.a 
                href="https://www.linkedin.com/company/hyperai-soultions" 
                className="text-gray-300 hover:text-cyan-400 transition-colors"
                whileHover={{ scale: 1.2, rotate: -5 }}
              >
                <Linkedin size={36} />
              </motion.a>
              <motion.a 
                href="https://x.com/HyperAISol" 
                className="text-gray-300 hover:text-cyan-400 transition-colors"
                whileHover={{ scale: 1.2, rotate: 5 }}
              >
                <Twitter size={36} />
              </motion.a>
              <motion.a 
                href="https://www.youtube.com/@HyperAISolutions" 
                className="text-gray-300 hover:text-cyan-400 transition-colors"
                whileHover={{ scale: 1.2, rotate: 5 }}
              >
                <Youtube size={36} />
              </motion.a>
            </div>
            <motion.button 
              className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-bold py-3 px-6 rounded-full hover:from-cyan-600 hover:to-blue-600 transition-all duration-300 transform hover:scale-105"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                window.location.href = '#contact';
              }}
            >
              Get in Touch
            </motion.button>
          </motion.div>
        </div>
      </motion.div>
      
      {/* Curved divider */}
      {/* <div className="absolute bottom-0 left-0 w-full mt-[200px] overflow-hidden">
        <svg 
          viewBox="0 0 1200 120" 
          preserveAspectRatio="none" 
          className="relative block w-full h-24 md:h-36"
          style={{ transform: 'rotate(180deg)' }}
        >
          <path 
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" 
            fill="#164e63"
          />
        </svg>
      </div> */}
    </section>
  );
};

export default About;