import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Clock, User } from 'lucide-react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogCollection = collection(db, 'blogs');
        const blogSnapshot = await getDocs(blogCollection);
        const blogList = blogSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setBlogs(blogList);
      } catch (err) {
        console.error('Error fetching blogs:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const getAuthorName = (author) => {
    if (typeof author === 'string') return author;
    if (typeof author === 'object' && author !== null) {
      return author.name || 'Anonymous';
    }
    return 'Anonymous';
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-cyan-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-cyan-900 text-white">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Error Loading Blogs</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-cyan-900 to-blue-900 text-white">
      <div className='fixed bg-cyan-900'>
        <Header />
      </div>
      
      <main className="container mx-auto px-[10%] min-h-[80vh] py-8 pt-[150px]">
        <div className="space-y-6">
          {blogs.map((blog, index) => (
            <article key={blog.id} className={`rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl ${index % 2 === 0 ? 'bg-gradient-to-r from-cyan-700 to-blue-700' : 'bg-gradient-to-r from-blue-700 to-cyan-700'}`}>
              <div className="p-6">
                <h2 className="text-2xl font-semibold mb-3">
                  <Link to={`/blog/${blog.id}`} className="hover:text-cyan-200 transition-colors duration-300">{blog.title}</Link>
                </h2>
                <p className="text-gray-200 mb-4">{blog.content?.substring(0, 550)}...</p>
                <div className="flex items-center text-sm text-gray-300">
                  <User size={16} className="mr-2" />
                  <span className="mr-4">{getAuthorName(blog.author)}</span>
                  <Clock size={16} className="mr-2" />
                  <span>{blog.readTime || '5 min read'}</span>
                </div>
              </div>
            </article>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default BlogsPage;