import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const ServiceCard = ({ title, description, icon, position }) => {
  const cardVariants = {
    center: { 
      x: '0%', 
      scale: 1, 
      zIndex: 3, 
      opacity: 1, 
      rotateY: 0,
      transition: { duration: 0.8, type: 'spring', stiffness: 200, damping: 25 }
    },
    left: { 
      x: '-100%', 
      scale: 0.8, 
      zIndex: 2, 
      opacity: 0.7, 
      rotateY: 15,
      transition: { duration: 0.8, type: 'spring', stiffness: 200, damping: 25 }
    },
    right: { 
      x: '100%', 
      scale: 0.8, 
      zIndex: 2, 
      opacity: 0.7, 
      rotateY: -15,
      transition: { duration: 0.8, type: 'spring', stiffness: 200, damping: 25 }
    },
    hidden: { 
      x: position === 'right' ? '200%' : '-200%', 
      scale: 0.8, 
      zIndex: 1, 
      opacity: 0,
      transition: { duration: 0.8 }
    }
  };

  return (
    <motion.div 
      className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-br from-blue-800 to-cyan-800 rounded-xl shadow-lg p-6 border border-cyan-700/30 overflow-hidden"
      style={{
        backgroundImage: 'url("serviceBG.jpg")',
        backgroundSize: 'cover'
      }}
      variants={cardVariants}
      initial="hidden"
      animate={position}
      exit="hidden"
    >
      <div className="relative z-10 h-full flex flex-col justify-center gap-5 items-center">
        <img src={icon} className="w-[50px] h-[50px]" alt="" />
        <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-white text-center">{title}</h3>
        <p className="text-blue-100 text-sm sm:text-md text-center">{description}</p>
      </div>
    </motion.div>
  );
};

const Services = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const services = [
    { 
      title: "Web Development", 
      description: "Create stunning, responsive websites tailored to your business needs. Our team ensures your website is fast, secure, and optimized for search engines, giving your brand a powerful online presence.", 
      icon: "design.png"
    },
    { 
      title: "App Development", 
      description: "Build powerful, user-friendly mobile applications for iOS and Android. Our developers craft apps with seamless performance, rich features, and an intuitive design that keeps your users engaged.", 
      icon: "smartphone.png" 
    },
    { 
      title: "AI Solutions", 
      description: "Implement cutting-edge artificial intelligence to enhance your business processes. From predictive analytics to automation, we help you harness AI to make smarter decisions and stay ahead of the competition.", 
      icon: "solution.png" 
    },
    { 
      title: "Chatbot Development", 
      description: "Design and deploy intelligent chatbots to improve customer engagement. Our chatbots are tailored to your brand's voice and provide 24/7 support, helping you connect with customers more efficiently.", 
      icon: "chat.png" 
    },
    { 
      title: "UI/UX Design", 
      description: "Create intuitive and visually appealing user interfaces for optimal user experience. We focus on user-centered design, ensuring that every interaction is smooth, enjoyable, and aligned with your brand identity.", 
      icon: "design.png" 
    },
    { 
      title: "Cloud Solutions", 
      description: "Leverage cloud technologies for scalable and efficient infrastructure. We offer cloud migration, management, and optimization services to help you reduce costs, enhance security, and ensure your systems are always available.", 
      icon: "clouds.png" 
    },
  ];

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + services.length) % services.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % services.length);
  };

  const getCardPosition = (index) => {
    const diff = (index - currentIndex + services.length) % services.length;
    if (diff === 0) return 'center';
    if (diff === 1 || diff === -(services.length - 1)) return 'right';
    if (diff === -1 || diff === (services.length - 1)) return 'left';
    return 'hidden';
  };

  return (
    <section id="services" className="bg-[#164e63]  py-20 px-4 sm:px-[10%] relative overflow-hidden">
      <div className="container mt-[50px]  mb-12">
        <motion.h2 
          className="text-3xl sm:text-4xl font-bold text-center mb-12 text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Our Services
        </motion.h2>
        <div className="relative" style={{ perspective: '1000px' }}>
          <div 
            className="relative mx-auto"
            style={{
              width: isMobile ? '80%' : '50%',
              height: isMobile ? '400px' : '500px',
              maxWidth: '400px'
            }}
          >
            <AnimatePresence initial={false}>
              {services.map((service, index) => (
                <ServiceCard
                  key={index}
                  {...service}
                  position={getCardPosition(index)}
                />
              ))}
            </AnimatePresence>
          </div>
          
          <div className="flex justify-center mt-8">
            <button 
              onClick={handlePrevious}
              className="bg-[#3c859f] opacity-50 text-white w-12 h-12 sm:w-16 sm:h-16 flex justify-center items-center rounded-full z-10 hover:bg-[#073f53] transition-colors duration-300 mr-4"
            >
              &#8592;
            </button>
            <button 
              onClick={handleNext}
              className="bg-[#3c859f] opacity-50 text-white w-12 h-12 sm:w-16 sm:h-16 flex justify-center items-center rounded-full z-10 hover:bg-[#073f53] transition-colors duration-300 ml-4"
            >
              &#8594;
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;