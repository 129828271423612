import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Clock, User, ArrowLeft } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Header from '../components/Header';
import Footer from '../components/Footer';

const BlogDetail = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const docRef = doc(db, 'blogs', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setBlog({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('Blog not found');
        }
      } catch (err) {
        setError('Error fetching blog: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) return (
    <div className="flex justify-center items-center h-screen bg-cyan-900">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-cyan-200"></div>
    </div>
  );

  if (error) return (
    <div className="flex justify-center items-center h-screen bg-cyan-900 text-cyan-100">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">Oops!</h1>
        <p className="text-xl">{error}</p>
        <Link to="/" className="mt-8 inline-block bg-cyan-700 text-cyan-100 px-6 py-2 rounded-full hover:bg-cyan-600 transition duration-300">
          Go Back Home
        </Link>
      </div>
    </div>
  );

  if (!blog) return (
    <div className="flex justify-center items-center h-screen bg-cyan-900 text-cyan-100">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">Blog Not Found</h1>
        <Link to="/" className="mt-8 inline-block bg-cyan-700 text-cyan-100 px-6 py-2 rounded-full hover:bg-cyan-600 transition duration-300">
          Go Back Home
        </Link>
      </div>
    </div>
  );

  const authorName = blog.author && typeof blog.author === 'object' ? blog.author.name : (blog.author || 'Anonymous');

  return (
    <div className="min-h-screen bg-cyan-900 text-cyan-100">
        <div className='fixed bg-cyan-900'>
            <Header />
        </div>
        <div className="container mx-auto pt-[150px] p-4 ">
            <Link to="/" className="inline-flex items-center text-cyan-200 hover:text-cyan-100 mb-8 transition duration-300">
                <ArrowLeft className="mr-2" />
                Back to Blogs
            </Link>
        </div>
      <div className="container mx-auto px-10 md:px-[10%] py-8">
        
        <article className="bg-cyan-800 rounded-lg shadow-2xl overflow-hidden">
          <div className="p-8">
            <h1 className="text-4xl font-bold mb-4 text-cyan-100">{blog.title}</h1>
            <div className="flex items-center text-sm text-cyan-300 mb-8">
              <span className="flex items-center mr-4">
                <User className="w-4 h-4 mr-1" />
                {authorName}
              </span>
              <span className="flex items-center">
                <Clock className="w-4 h-4 mr-1" />
                {blog.createdAt ? new Date(blog.createdAt.toDate()).toLocaleDateString() : 'Unknown date'}
              </span>
            </div>
            <div className="prose prose-lg prose-invert max-w-none">
              <ReactMarkdown 
                remarkPlugins={[remarkGfm]}
                components={{
                  h1: ({node, ...props}) => <h1 className="text-3xl font-bold mt-6 mb-4 text-cyan-100" {...props} />,
                  h2: ({node, ...props}) => <h2 className="text-2xl font-bold mt-5 mb-3 text-cyan-100" {...props} />,
                  h3: ({node, ...props}) => <h3 className="text-xl font-bold mt-4 mb-2 text-cyan-100" {...props} />,
                  p: ({node, ...props}) => <p className="mb-4 text-cyan-100" {...props} />,
                  a: ({node, ...props}) => <a className="text-cyan-300 hover:text-cyan-200" {...props} />,
                  ul: ({node, ...props}) => <ul className="list-disc list-inside mb-4" {...props} />,
                  ol: ({node, ...props}) => <ol className="list-decimal list-inside mb-4" {...props} />,
                  blockquote: ({node, ...props}) => <blockquote className="border-l-4 border-cyan-600 pl-4 py-2 mb-4 italic" {...props} />,
                  code: ({node, inline, ...props}) => 
                    inline 
                      ? <code className="bg-cyan-700 rounded px-1" {...props} />
                      : <code className="block bg-cyan-700 rounded p-2 whitespace-pre-wrap" {...props} />,
                }}
              >
                {blog.content}
              </ReactMarkdown>
            </div>
          </div>
        </article>
      </div>

      <Footer />
    </div>
  );
};

export default BlogDetail;