import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_vejwt0b"
    const templateId = "template_agvdtpg"
    const publicKey = "zd9Kk8FYk5OhGuUob"

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      to_name: 'HyperAI',
      message: formData.description
    }

    emailjs.send(serviceId, templateId, templateParams, publicKey)
    .then((res) => {
      console.log("Email Sent Successfully.. ", res);
      setFormData({
        name: '',
        email: '',
        description: '',
      });
    }).catch(err => {
      console.log("Error: ", err);
    });
  };

  const InputField = ({ name, type = 'text' }) => (
    <div className="group relative mb-6">
      <input
        type={type}
        id={name}
        name={name}
        value={formData[name]}
        onChange={handleChange}
        className="w-full px-4 py-3 bg-white bg-opacity-20 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-400 transition duration-300 text-gray-800 placeholder-gray-500 backdrop-blur-sm"
        required
        placeholder={name.charAt(0).toUpperCase() + name.slice(1)}
      />
      {/* <label
        htmlFor={name}
        className="absolute left-4 top-3 text-sm text-gray-600 transition-all duration-300 pointer-events-none transform origin-left group-focus-within:scale-75 group-focus-within:-translate-y-6 opacity-70"
      >
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label> */}
      <div className="absolute bottom-0 left-0 w-0 h-0.5 bg-gradient-to-r from-cyan-400 to-blue-500 transition-all duration-300 group-focus-within:w-full"></div>
    </div>
  );

  return (
    <div className="w-[90%] max-w-md mx-auto p-6 mb-12 bg-[#c3efff] rounded-2xl shadow-xl relative overflow-hidden"
      style={{
        backgroundImage: 'url("serviceBG.jpg")',
        backgroundSize: 'cover'
      }}
    >
      <div className="relative z-10">
        <h2 className="text-3xl font-extrabold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#164e63] to-cyan-600 text-center">HyperAI Contact</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <InputField name="name" />
          <InputField name="email" type="email" />
          <div className="group relative">
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows="4"
              className="w-full px-4 py-3 bg-white bg-opacity-20 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-400 transition duration-300 text-gray-800 placeholder-gray-500 backdrop-blur-sm"
              required
              placeholder="Description"
            ></textarea>
            {/* <label
              htmlFor="description"
              className="absolute left-4 top-3 text-sm text-gray-600 transition-all duration-300 pointer-events-none transform origin-left group-focus-within:scale-75 group-focus-within:-translate-y-6 opacity-70"
            >
              Description
            </label> */}
            <div className="absolute bottom-0 left-0 w-0 h-0.5 bg-gradient-to-r from-cyan-400 to-blue-500 transition-all duration-300 group-focus-within:w-full"></div>
          </div>
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-[#164e63] to-cyan-500 text-white font-bold py-3 px-6 rounded-full hover:from-blue-600 hover:to-cyan-600 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-opacity-50 relative overflow-hidden group"
          >
            <span className="relative z-10">Submit</span>
            <span className="absolute inset-0 bg-gradient-to-r from-blue-400 to-cyan-400 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
          </button>
        </form>
      </div>
      <div className="absolute -top-24 -right-24 w-64 h-64 bg-blue-300 rounded-full opacity-30 animate-pulse blur-3xl"></div>
      <div className="absolute -bottom-24 -left-24 w-64 h-64 bg-cyan-300 rounded-full opacity-30 animate-pulse blur-3xl"></div>
    </div>
  );
};

export default ContactForm;