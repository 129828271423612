import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const ParallaxHero = () => {
  const [background, setBackground] = useState(20);
  const parallaxRef = useRef(null);
  const fgRef = useRef(null);
  const copyRef = useRef(null);
  const btnRef = useRef(null);
  const autoScrollTriggeredRef = useRef(false);

  useEffect(() => {
    let autoScrolling = false;

    const triggerAutoScroll = () => {
      if (autoScrolling || autoScrollTriggeredRef.current) return;

      console.log('Triggering auto-scroll');
      autoScrolling = true;
      autoScrollTriggeredRef.current = true;

      gsap.to(window, {
        duration: 2,
        scrollTo: {
          y: 2333,
          autoKill: false
        },
        ease: 'power2.inOut',
        onStart: () => console.log('Auto-scroll started'),
        onComplete: () => {
          console.log('Auto-scroll completed');
          autoScrolling = false;
        }
      });
    };

    const handleScroll = () => {
      const scrollY = window.scrollY;
      console.log('Current scroll position:', scrollY);

      if (scrollY >= 50 && scrollY < 2333 && !autoScrollTriggeredRef.current) {
        triggerAutoScroll();
      }
    };

    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: parallaxRef.current,
          start: 'top top',
          end: '+=300%',
          scrub: true,
          pin: true,
          onUpdate: (self) => {
            setBackground(Math.ceil(self.progress * 100 + 20));
          }
        }
      });

      // Initial state
      gsap.set(copyRef.current, { y: '0%', zIndex: 0 });
      gsap.set(btnRef.current, { opacity: 0 });

      // Animation sequence
      tl.to(copyRef.current, { y: '-120%', duration: 0.4 }, 0)
        .to(copyRef.current, { zIndex: 2 }, 0.4)
        .to(btnRef.current, { opacity: 1, duration: 0.2 }, 0.5)
        .to(copyRef.current, { y: '-50%', duration: 0.3 }, 0.6);
    });

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Check initial scroll position
    handleScroll();

    return () => {
      ctx.revert();
      window.removeEventListener('scroll', handleScroll);
      autoScrollTriggeredRef.current = false;
    };
  }, []);

  return (
    <div className='parallax-outer'>
      <div ref={parallaxRef} className='parallax'>
        <img ref={fgRef} className="fg" src="foreground.png" width='100%' height='100%' alt="" />
        <div ref={copyRef} className='copy'>
          <h1 className='text-white text-7xl text-center'>Your Vision, Our Expertise</h1>
          <span className='text-center mt-10 text-blue-100'>Let's Create Something Extraordinary Together.</span>
          <button className='bg-gradient-to-r from-[#164e63] to-cyan-500 text-white hover:shadow-lg' onClick={() => {
              window.location.href = '#contact';
            }} ref={btnRef}>Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default ParallaxHero;