// components/Header.js
import React, { useState, useEffect } from 'react';
import Logo from '../hyperai.png';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [btnScrolled, setBtnScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
      setBtnScrolled(window.scrollY > 2700);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed w-[390px] md:w-full z-50 transition-all duration-300 ${scrolled ? 'bg-transparent bg-opacity-80 backdrop-blur-md shadow-lg' : 'bg-transparent'}`}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className={`w-40 h-10 bg-transparent py-8 rounded-lg flex items-center justify-center`}>
            <img src='WEB.png' className="" alt="Logo" />
          </div>
        </div>
        <nav className="hidden md:flex space-x-8">
          {['Home', 'Services', 'About', 'Contact', 'Blogs'].map((item) => (
            <a
              key={item}
              href={`${item ==='Blogs'? item.toLowerCase(): ('/#'+item.toLowerCase())}`}
              className={`text-lg font-medium transition duration-300 text-white hover:text-cyan-300`}
            >
              {item}
            </a>
          ))}
        </nav>
        {mobileMenuOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 bg-[#427c93] shadow-lg py-2">
            <ul className="flex flex-col">
              {['Home', 'Services', 'About', 'Contact', 'Blogs'].map((item) => (
                <li key={item}>
                  <a
                    href={`${item ==='Blogs'? item.toLowerCase(): ('/#'+item.toLowerCase())}`}
                    className="block py-2 px-4 text-white hover:bg-[#6b9db1]"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="hidden md:block">
          <a
            href="#contact"
            className={`py-2 px-4 rounded-full font-bold transition duration-300 ${
              btnScrolled
                ? 'bg-gradient-to-r from-[#164e63] to-cyan-500 text-white hover:shadow-lg'
                : 'bg-white text-[#164e63] hover:bg-blue-50'
            }`}
          >
            Get Started
          </a>
        </div>
        <div className="md:hidden">
          <button 
            className="text-white hover:text-gray-300"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <div className="absolute -inset-[10px] bg-gradient-to-r from-blue-500/30 to-cyan-500/30 blur-3xl opacity-30 animate-pulse"></div>
      </div>
    </header>
  );
};

export default Header;
