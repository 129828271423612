import React from 'react'

import {auth, provider} from '../firebaseConfig'
import {signInWithPopup} from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

const Login = ({setIsAuth}) => {

    const navigate = useNavigate()

    const signInWithGoogle = ()=>{
        signInWithPopup(auth, provider).then((result)=>{
            localStorage.setItem('isAuth', true)
            setIsAuth(true)
            navigate('/new-blog')
        })
    }


  return (
    <div className="flex flex-col justify-center items-center h-screen">
        <h1 className='text-3xl mb-[100px] font-bold'>Login</h1>
        <button className='w-[250px] h-[50px] rounded-full bg-blue-500 text-white' onClick={signInWithGoogle}>Login to add new blog</button>
    </div>
  )
}

export default Login