import React, { useState, useEffect, useRef } from 'react';

const ProblemSolutionSection = () => {
  const [visibleItems, setVisibleItems] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const sectionRef = useRef(null);

  const problems = [
    "Complex AI integration challenges",
    "Lack of specialized AI expertise",
    "Slow development of AI applications",
    "Difficulty in scaling AI solutions",
    "Limited natural language processing capabilities",
  ];

  const solutions = [
    "Seamless AI solutions tailored to your needs",
    "Access to top AI developers and researchers",
    "Rapid prototyping and deployment of AI systems",
    "Scalable AI architectures for growing businesses",
    "Advanced NLP solutions for diverse applications",
  ];

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640); // 640px is the 'sm' breakpoint in Tailwind
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let timer;
    if (isInView) {
      timer = setInterval(() => {
        setVisibleItems(prev => {
          if (prev < problems.length) {
            return prev + 1;
          } else {
            clearInterval(timer);
            return prev;
          }
        });
      }, 500);
    }

    return () => clearInterval(timer);
  }, [isInView]);

  const getMargin = (index, total, isLeft) => {
    if (isMobile) return '';
    const mid = Math.floor(total / 2);
    const maxMargin = 40;
    if (isLeft) {
      return index <= mid 
        ? `30px -${maxMargin * (mid - index) / mid}px 0 auto` 
        : `30px -${maxMargin * (index - mid) / (total - mid - 1)}px 0 auto`;
    } else {
      return index <= mid 
        ? `30px auto 0 -${maxMargin * (mid - index) / mid}px` 
        : `30px auto 0 -${maxMargin * (index - mid) / (total - mid - 1)}px`;
    }
  };

  return (
    <section ref={sectionRef} className="py-10 sm:py-20 bg-[#164e63] text-white overflow-hidden">
      <div className="container mx-auto px-4 relative">
        <div className="flex flex-col sm:flex-row justify-between mb-8 sm:mb-12 sm:px-[10%] text-center sm:text-left">
          <div className="mb-4 sm:mb-0">
            <h2 className="text-2xl sm:text-3xl font-bold mb-1 sm:mb-2">PROBLEMS</h2>
            <p className="text-[#4590ab] text-sm sm:text-base">WITH TRADITIONAL WAY</p>
          </div>
          <div className="sm:text-right hidden sm:block">
            <h2 className="text-2xl sm:text-3xl font-bold mb-1 sm:mb-2">SOLUTIONS</h2>
            <p className="text-[#4590ab] text-sm sm:text-base">THE HYPERAI WAY</p>
          </div>
        </div>
        
        <div className="relative flex flex-col sm:flex-row justify-center items-center min-h-[500px] sm:min-h-[600px]">
          <div className="w-full sm:w-4/12 sm:absolute sm:left-0 space-y-4 sm:space-y-8 mb-8 sm:mb-0">
            {problems.map((problem, index) => (
              <div 
                key={`problem-${index}`} 
                className={`flex items-center justify-end transition-all duration-500 ${
                  !isMobile && index < visibleItems ? 'opacity-100 translate-x-0' : 'sm:opacity-0 sm:-translate-x-full'
                }`}
                style={{ margin: getMargin(index, problems.length, true) }}
              >
                <p className="text-right mr-2 sm:mr-4 text-sm sm:text-base">{problem}</p>
                <span className="text-red-500">❌</span>
              </div>
            ))}
          </div>

          <div className='w-full sm:w-3/12 flex justify-center items-center mb-8 sm:mb-0'>
            <div className="w-[200px] sm:w-[250px] relative">
              <div className="bg-[#1f647d] rounded-full p-6 sm:p-8 text-center relative z-10">
                <div className="bg-[#327892] rounded-full p-4 sm:p-6">
                  <p className="text-lg sm:text-xl font-bold mb-1 mt-9">We fix this.</p>
                  <p className="text-base sm:text-lg mb-9">Fast and smart.</p>
                </div>
              </div>
              <div className="absolute inset-0 -z-10 overflow-hidden">
                <div className="absolute -inset-[10px] bg-gradient-to-r from-blue-500/30 to-cyan-500/30 blur-3xl opacity-30 animate-pulse"></div>
              </div>
            </div>
          </div>


          <div className="sm:text-right block sm:hidden">
            <h2 className="text-2xl sm:text-3xl font-bold mb-1 sm:mb-2">SOLUTIONS</h2>
            <p className="text-[#4590ab] text-sm sm:text-base">THE HYPERAI WAY</p>
          </div>

          <div className="w-full sm:w-4/12 sm:absolute sm:right-0 space-y-4 sm:space-y-8">
            {solutions.map((solution, index) => (
              <div 
                key={`solution-${index}`} 
                className={`flex items-center transition-all duration-500 ${
                  !isMobile && index < visibleItems ? 'opacity-100 translate-x-0' : 'sm:opacity-0 sm:translate-x-full'
                }`}
                style={{ margin: getMargin(index, solutions.length, false) }}
              >
                <span className="text-green-500 mr-2 sm:mr-4">✅</span>
                <p className="text-sm sm:text-base">{solution}</p>
              </div>
            ))}
          </div>

          {/* Curved lines (hidden on mobile) */}
          <svg className="absolute inset-0 w-full h-full hidden sm:block" style={{zIndex: 0}}>
            <g fill="none" stroke="#4B5563" strokeWidth="1" strokeDasharray="5,5">
              {[...Array(5)].map((_, i) => (
                <React.Fragment key={i}>
                  <path d={`M30% ${15+i*17.5}% Q50% 50% 70% ${15+i*17.5}%`} />
                </React.Fragment>
              ))}
            </g>
          </svg>
        </div>
      </div>
    </section>
  );
};

export default ProblemSolutionSection;