import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {getAuth, GoogleAuthProvider} from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBkPPG6m5bWS-VRTseMokWUkxl-2ohPYYk",
    authDomain: "hyperaiblogs.firebaseapp.com",
    projectId: "hyperaiblogs",
    storageBucket: "hyperaiblogs.appspot.com",
    messagingSenderId: "1021788051084",
    appId: "1:1021788051084:web:c1720d86d7538ab3e1cda9",
    measurementId: "G-VHCH2MXZPG"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()

export const db = getFirestore(app);