import React from 'react';

const NotFound = () => {
  return (
    <div className='w-screen h-screen flex justify-center items-center'>
        <h1>404 - Page Not Found</h1>
    </div>
  )
};

export default NotFound;